<template>
  <div id="default-wrapper_f" class="default-wrapper">
    <CommonAnnouncement
      v-model:visible="showNavBarAlert"
      class="anno"
      :style="layoutStyle"
      :on-close="handleAnnClose"
    ></CommonAnnouncement>

    <PagesNewHomeCommonNavBar
      v-if="showNavBar"
      :style="layoutStyle"
      @login-button-click="showLoginDialogHandler"
      @get-layout-style="getLayoutStyle"
    />

    <CommonCookiesDrawer :style="layoutStyle" />

    <div :style="layoutStyle">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUser } from "@/store/user"
import { SessStorageKey } from "@/constant/cache"

const use_user = useUser()

let showNavBar = computed(() => {
  return useNavBarShow().value
})

// navbar login点击
const showLoginDialogHandler = () => {
  use_user.showLoginDialogAction()
}

const showNavBarAlert = useShowNavBarAlert()

function handleAnnClose() {
  setLocalStorage(SessStorageKey.HIDE_ANNO_FLAG, "true", "sessionStorage")
}

const layoutStyle = ref()
const getLayoutStyle = (style: CSSStyleValue) => {
  layoutStyle.value = style
}

// 单点登录
const { bootstrap } = useSSO()
bootstrap()
</script>

<style scoped lang="scss">
.default-wrapper {
  transition: all 0.3s;
  :deep(.anno) {
    position: fixed;
    bottom: 100%;
    .text {
      margin: 0 16px;
      font-size: 16px;
      @include device-max-width-900 {
        text-align: left;
      }
      @include device-max-width-600 {
        font-size: 12px;
        line-height: 14px;
        margin: 0 12px;
      }
      @include device-max-width-375 {
        margin: 0 4px;
      }
    }

    .e-i {
      font-size: 16px;
      @include device-max-width-600 {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
</style>
<style lang="scss">
@import url("@/assets/scss/font.scss");
.welcome-page-dialog {
  &.login-more-dialog {
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
        .sign-in {
          .email-exist {
            display: block;
          }
        }
      }
    }

    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
        .sign-up {
          .other-sign-button {
            flex-direction: column;
            .other-sign-button-item:nth-child(1) {
              margin-right: 0;
              margin-bottom: 14px;
            }
            .other-sign-button-item {
              line-height: 42px;
            }
          }
        }
      }
    }
  }

  &.contact-sales-dialog {
    @include device-max-width-1400 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
        .contact-sales {
          flex-direction: column;
          border-radius: 8px;
          .contact-sales-left {
            width: 100%;
            border-radius: 8px;
            .desc-content {
              margin-bottom: 24px;
            }
            .ev-title {
              bottom: 20px;
            }
          }
          .contact-sales-right-content {
            .submit {
              position: sticky;
              bottom: 20px;
              z-index: 1001;
            }
          }
        }
      }
    }
    @include device-max-width-900 {
      .content {
        width: 80%;
        .contact-sales {
          .contact-sales-right-content {
            .submit-tip {
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }

    @include device-max-width-600 {
      .content {
        width: 80%;
        .contact-sales {
          .contact-sales-left {
            padding: 20px;
            .title {
              font-size: 24px;
              line-height: 26px;
            }
            .desc-content {
              margin: 10px 0;
            }
            .ev-title {
              display: none;
            }
          }
          .contact-sales-right-content {
            padding: 20px;
            .top-title {
              font-size: 16px;
              line-height: 18px;
            }
            .content-form {
              .el-form-item {
                grid-column: span 2;
              }
            }
          }
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 75%;
        .close-icon {
          right: -32px;
        }
      }
    }
  }

  &.payment-status_dialog {
    .share_cover {
      background: rgba(0, 0, 0, 0.4);
    }
    @include device-max-width-900 {
      .content {
        width: 80%;
        .content-d {
          width: 100% !important;
        }
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
